a {
}

a:hover {
    text-decoration: none;
}

.profile-pic {
    height: 4vmin;
    margin-right: 10px;
    border-radius: 50px;
}

div.media {
    margin-top: 30px;
    margin-bottom: 10px;
}

div.media .media-heading .jobTotalDuration {
    color: #666;
    font-size: 14px;
    display: block;
}

div.media img {
    height: 10vmin;
    margin-right: 10px;
    border-radius: 50px;
}

div.media .jobDuration {
    color: #444;
    display: block;
}

div.media .jobLocation {
    color: #666;
}

.formLabel {
    font-weight: bold;
}

.certificateLink {
    color: cornflowerblue;
}

footer {
    margin-top: 20px;
    margin-bottom: 10px;
    border-top: #444444;
    text-align: center;
}

footer a {
    color: black;
    font-size: 30px;
    margin-right: 10px;
    margin-left: 10px;
}

.nav-tabs .nav-link{
  cursor: pointer;
}

.productDescription{
    font-size: 12px;
}

.jumbotron{
    background-image: linear-gradient(277deg, #1b81d7 0%, #1f4869 100%);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}

.jumbotron .container .display-3{
    color:#FFF
}

.jumbotron .container .lead{
    color:#FFF
}

.nav-link {
    font-weight: bolder;
}

.nav-link:hover {
    font-weight: bolder;
    font-style: italic;
}

.top-nav-link {
    color: 
    rgba(255,255,255,.8) !important;
}

.top-nav-link:hover{
    color: 
    rgba(255,255,255) !important;
}